import React from 'react'
import { Clerk } from '@formclerk/react'

const Form = ({ children }) => (
  <Clerk
    clerkID='4354f0a8-8904-4d66-b1b5-59c5fc62067d'
    Initializing={() => <p>Formuläret laddas...</p>}
    Processing={() => <p>Skickar kontaktinformation...</p>}
    Success={() => (
      <p>Tack för att du vill bli kontaktad! Vi hör av oss så snart vi kan.</p>
    )}
    Failure={() => (
      <p>
        Hmm... Något verkar ha blivit fel, du får gärna kontakta oss direkt
        istället.
      </p>
    )}
  >
    <label className='clerk__label'>
      E-postadress *
      <input type='text' className='clerk__input' name='e-post' required />
    </label>
    <label className='clerk__label'>
      Telefonnummer
      <input type='text' className='clerk__input' name='telefonnummer' />
    </label>
    <label className='clerk__label'>
      Eventuellt meddelande
      <textarea name='meddelande' rows='7' className='clerk__field'></textarea>
    </label>
    {children}
    <input type='submit' value='Kontakta mig' className='clerk__submit' />
  </Clerk>
)

export default Form
