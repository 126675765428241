import React from 'react'

import Form from 'components/Form'
import Layout from 'components/Layout'
import siteMetadata from 'query/SiteMetadata'

const Kontakt = () => {
  const { email, phone } = siteMetadata()

  return (
    <Layout
      seo={{
        title: 'Kontakt',
        description:
          'Maila eller ring oss gärna för att boka ett möte! För våra kunder finns vi även tillgängliga i vår portal.',
      }}
    >
      <oma-grid-row>
        <section className='section'>
          <h1 className='page__heading'>Kontakt</h1>
          <p className='section__text'>
            Vi kommer gärna i kontakt med dig och samtalar kring vad du behöver
            hjälp med. Kanske är det så att du inte hinner med din bokföring
            eller att du skulle behöva frigöra tid för att göra det du faktiskt
            vill hålla på med i ditt företag. Hör av dig till oss så kan vi boka
            ett kostnadsfritt möte!
          </p>
          <h2 className='section__sub-heading'>Vi kontaktar dig</h2>
          <Form />
          <h2 className='section__sub-heading'>Du kontaktar oss</h2>
          <p className='section__text'>
            Maila oss på <oma-link to={email} class='contact-form__link' />
            eller ring <oma-link to={phone} class='contact-form__link' />.
          </p>
          <h2 className='section__sub-heading'>Arbetstider</h2>
          <p className='section__text'>Måndag - Fredag: 8:00-16:00</p>
        </section>
      </oma-grid-row>
    </Layout>
  )
}

export default Kontakt
